import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

import {
  clock,
  arrowLeftWhite,
  BundleBanner,
  BundleBannerMobile,
} from "../../Assets/images";
import { mailIcon } from "../../Assets/svgs";
import { Alert, Button, CircularProgress, Modal } from "@mui/material";

import { AuthContext } from "../../App";

import DashboardHeader from "./DashboardComponents/DashboardHeader/DashboardHeader";
import ExamCard from "./DashboardComponents/ExamCard/ExamCard";
import Bookshelf from "./DashboardComponents/Bookshelf/Bookshelf";
import NFOSessionCard from "./DashboardComponents/NFOSessionCard/NFOSessionCard";
import MockQuizCard from "./DashboardComponents/MockQuizCard/MockQuizCard";
import MobileDashboardHeader from "./DashboardComponents/MobileHeaderDashboard/MobileDashboardHeader";
import MobileUserInfoModal from "./DashboardComponents/MobileUserInfoModal/MobileUserInfoModal";
import MobileMockQuizCard from "./DashboardComponents/MockQuizCard/MobileMockQuizCard";

import "./Dashboard.css";
import "./DashboardComponents/DashboardComponentsStyles.css";
import { setUserId, setUserProperties } from "firebase/analytics";
import { fAnalytics } from "../../Utils/firebase/firebaseConfig";
import UserDetails from "./DashboardComponents/UserDetails/UserDetails";

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    auth: {
      student_username,
      name,
      grade,
      school,
      showMockOlympiad,
      showLiveSession,
      showPracticeTestAndQuestionBankBanner,
    },
  } = useContext(AuthContext);
  const urlParams = new URLSearchParams(window.location.search);
  console.log({ student_username });
  const orderId = urlParams.get("order_id");
  console.log("order_id param = ", orderId);
  const page = urlParams.get("page");
  console.log("page param = ", page);
  const [purchasedItems, setPurchasedItems] = useState(false);
  const [syncingPurchase, setSyncingPurchase] = useState(true);
  const token = localStorage.getItem("accessToken");
  const { param } = useParams<{ param: string }>();
  console.log("param = ", param);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mobileUserInfoVisible, setMobileUserInfoVisible] = useState(false);
  if (student_username) {
    setUserId(fAnalytics, student_username);
    setUserProperties(fAnalytics, {
      userId: student_username,
      grade: grade,
      school: school,
    });
  }
  const handleCloseMobileUserInfo = () => {
    setMobileUserInfoVisible(false);
  };

  const handleShowMobileUserInfo = () => {
    setMobileUserInfoVisible(true);
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="portal">
      <MobileDashboardHeader cbHandleOpenUserInfo={handleShowMobileUserInfo} />
      <div className="contentwrap">
        <DashboardHeader />
        <div className="contentwrap1">
          <UserDetails />
          <div className="rightpanel">
            {showPracticeTestAndQuestionBankBanner && (
              <div
                onClick={() => {
                  openInSameTab(
                    `https://checkout.nationalfinanceolympiad.com/CheckoutPage/?itemId=31&token=${token}&userName=${student_username}`
                  );
                }}
              >
                <img
                  src={BundleBannerMobile}
                  className="bundle-banner mobile"
                />
                <img src={BundleBanner} className="bundle-banner desktop" />
              </div>
            )}

            <div className="pagetitle">
              <b className="nfo-content">NFO Study Material</b>
              {/* <img className="line" alt="" src={TitleLine} /> */}
              <div className="line"></div>
            </div>

            <Bookshelf />

            <div className="nfo-portal-v2-bottom-cards">
              {showLiveSession && !(grade > 8 && grade < 11) && (
                <NFOSessionCard />
              )}

              {showMockOlympiad && (
                <>
                  <MockQuizCard
                    cbHandleMockClick={() => {
                      const token = localStorage.getItem("accessToken");
                      openInSameTab(
                        `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                      );
                    }}
                  />
                  <MobileMockQuizCard
                    cbHandleMockClick={() => {
                      const token = localStorage.getItem("accessToken");
                      openInSameTab(
                        `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                      );
                    }}
                  />
                </>
              )}
            </div>

            <div className="mobile-bottom-buttons">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <button className="mobile-supportbutton">
                  <img className="mobile-vector-icon" alt="" src={mailIcon} />

                  <a
                    className="mobile-support-text"
                    href="mailto:support@nationalfinanceolympiad.com"
                  >
                    Contact NFO Support
                  </a>
                </button>
                <button
                  className="mobile-supportbutton"
                  onClick={() => {
                    navigate("/orderHistory");
                  }}
                >
                  <img className="mobile-vector-icon" src={clock} alt="O" />
                  <div className="mobile-support-text">Purchase History</div>
                </button>
              </div>
              <div>
                <button
                  className="mobile-signout-button"
                  onClick={() => {
                    Cookies.remove("accessToken");
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                  }}
                >
                  Signout of NFO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {error.length > 0 && (
        <Alert
          style={{ position: "absolute", top: 10, left: 10 }}
          severity="error"
        >
          {error}
        </Alert>
      )}
      <MobileUserInfoModal
        isVisible={mobileUserInfoVisible}
        onClose={() => {
          handleCloseMobileUserInfo();
        }}
      >
        <div className="mobile-userInfoWrap">
          <button
            className="mobile-userProfileBackButton"
            onClick={handleCloseMobileUserInfo}
          >
            <img src={arrowLeftWhite} alt="&lt;-" />
            &nbsp;Go back
          </button>
          <br />
          <div className="mobile-studentid">
            <b className="mobile-name">{name}</b>
            <div className="line" />
            <div className="mobile-grade">{grade}th Grade</div>
            <div className="line" />
            <div className="mobile-grade">
              <div className="mobile-school">{school} </div>
            </div>
            <div className="line" />
            <div className="mobile-grade">
              <div className="mobile-school">Roll No.- {student_username} </div>
            </div>
          </div>
        </div>
      </MobileUserInfoModal>
    </div>
  );
};

export default Dashboard;
