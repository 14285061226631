import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./ExamCard.css";
import { AuthContext } from "../../../../App";

const ExamCard = () => {
  const navigate = useNavigate();
  const {
    auth: { report_generated, examDate, examLink },
  } = useContext(AuthContext);

  const [examStatus, setExamStatus] = useState(0);
  const [examDay, setExamDay] = useState("");
  const [examStartTime, setExamStartTime] = useState("");
  const [examEndTime, setExamEndTime] = useState("");

  const cardHeaders = [
    "Exam not Scheduled",
    "Your Olympiad is scheduled on",
    "Your Olympiad starts on",
    "Thank you for participating",
    "Thank you for participating",
  ];

  const cardBody = [
    "Your Exam date has not been assigned yet",
    "",
    "",
    "Your NFO Level-1 results are available for download. Click to download",
    "Your NFO Level-1 results will be announced soon",
  ];

  const cardBG = [
    "rgba(255, 171, 46, 0.2)",
    "#bbd2f552",
    "rgba(255, 171, 46, 0.2)",
    "rgba(188, 255, 46, 0.2)",
    "rgba(188, 255, 46, 0.2)",
  ];

  const cardColor = [
    "rgba(170, 58, 21, 1)",
    "#2c6ab1",
    "rgba(170, 58, 21, 1)",
    "rgba(15, 102, 8, 1)",
    "rgba(15, 102, 8, 1)",
  ];

  const button1Txt = [
    "Contact Support",
    "View Exam Rules",
    "Start Olympiad",
    "Certificate",
    "",
  ];

  const handleContactSupportClick = () => {
    window.open(
      "mailto:email@example.com?subject=Subject&body=Body%20goes%20here"
    );
  };

  const handleExamRulesClick = () => {
    navigate("/pdf-preview", {
      state: {
        type: "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  const handleStartOlympiadClick = () => {
    window.open(examLink);
  };

  const handleCertificateViewer = () => {
    navigate("/certificate-viewer", {
      state: {
        type: "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  const handleSecondButtonClick = () => {
    navigate("/report-viewer", {
      state: {
        type: "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  const button1Links = [
    handleContactSupportClick,
    handleExamRulesClick,
    handleStartOlympiadClick,
    handleCertificateViewer,
    handleExamRulesClick,
  ];

  const dateFormat: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const getExamStatus = () => {
    const dt =
      (new Date(examDate).getTime() - new Date().getTime()) / (1000 * 60 * 60);
    // console.log("exam date: ", dt);

    const examStatus =
      dt > 36
        ? 1
        : dt < 36 && dt > -2
        ? 2
        : dt < -2 && report_generated
        ? 3
        : dt < -2 && !report_generated
        ? 4
        : 0;
    // console.log("exam status: ", examStatus);
    setExamStatus(examStatus);
  };

  const getExamTiming = () => {
    const examStart = new Date(examDate);
    const examEnd = new Date(
      new Date(examStart).setHours(examStart.getHours() + 1)
    );

    setExamDay(new Date(examDate).toLocaleDateString("en-US", dateFormat));
    setExamStartTime(examStart.toLocaleTimeString("en-US", timeFormat));
    setExamEndTime(examEnd.toLocaleTimeString("en-US", timeFormat));
  };

  useEffect(() => {
    getExamStatus();
    getExamTiming();
  }, [examDate]);

  return (
    <div
      className="exam-card"
      style={{
        backgroundColor: cardBG[examStatus],
        color: cardColor[examStatus],
        boxShadow: `0px 0px 20px 1px ${cardBG[examStatus]}`,
        border: `0.5px solid ${cardBG[examStatus]}`,
      }}
    >
      <div className="exam-card-content">
        <p className="exam-card-head">{cardHeaders[examStatus]}</p>
        {examStatus === 1 || examStatus === 2 ? (
          <p className="exam-card-body">
            {examDay} <br /> {examStartTime} to {examEndTime}
          </p>
        ) : (
          <p className="exam-card-body">{cardBody[examStatus]}</p>
        )}
        <div
          className="card-buttons"
          style={{
            color: cardBG[examStatus],
          }}
        >
          {button1Txt[examStatus] && (
            <div
              className="card-button"
              style={{
                backgroundColor: cardColor[examStatus],
              }}
              onClick={button1Links[examStatus]}
            >
              {button1Txt[examStatus]}
            </div>
          )}
          {examStatus == 2 && (
            <div
              className="card-button"
              style={{
                color: cardColor[examStatus],
                fontSize: "1.1rem",
                cursor: "pointer",
              }}
              onClick={handleExamRulesClick}
            >
              <u>Exam Rules</u>
            </div>
          )}
          {examStatus == 3 && (
            <div
              className="card-button"
              style={{
                backgroundColor: cardColor[examStatus],
                cursor: "pointer",
              }}
              onClick={handleSecondButtonClick}
            >
              Report
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExamCard;
