import React, { useContext } from "react";
import { AuthContext } from "../../../../App";
import Cookies from "js-cookie";
import ExamCard from "../ExamCard/ExamCard";
import { mailIcon } from "../../../../Assets/svgs";
import "./UserDetails.css";

const UserDetails = () => {
  const {
    auth: { student_username, name, grade, school, showMockOlympiad },
  } = useContext(AuthContext);
  return (
    <div className="studentid-wrap">
      <div className="studentid">
        <b className="name">{name}</b>
        <div className="line" />
        <div className="grade">{grade}th Grade</div>
        <div className="line" />
        <div className="grade">
          <div className="grade">{school} </div>
        </div>
        <div className="line" />
        <div className="grade">
          <div className="grade">Roll No.- {student_username} </div>
        </div>
        <div className="line" />
        <div
          onClick={() => {
            Cookies.remove("accessToken");
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
          }}
          className="sign-out"
        >
          Sign-Out
        </div>
      </div>
      <div className="laptop-exam-cards">
        <ExamCard />
      </div>

      <button className="supportbutton">
        <img className="vector-icon" alt="" src={mailIcon} />
        <a
          className="support-text"
          href="mailto:support@nationalfinanceolympiad.com"
        >
          Contact NFO Support
        </a>
      </button>
    </div>
  );
};

export default UserDetails;
